.waveform-display{
  position: absolute;
  width:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  div{
    position: absolute;
    font-size: 12px;
  }
  .waveform-display-position{
    left:0px;
  }
  .waveform-display-total{
    right:0px;
  }
}