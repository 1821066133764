.container-auth{
  flex-direction: column;
  width:300px;
  margin:0 auto;
  svg{
    width:100px;
    margin:0 auto;
  }
  .form-auth{
    background-color: transparent;
    .form-submit-auth{
      border-color: $colour-background-mid;
    }
  }
  .forgot-password{
    text-align: center;
    font-size: 12px;
    color:$colour-text;
    cursor: pointer;
  }
  .reset-password-wrapper{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .password-reset-container{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .message{
      text-align: center;
    }
  }
}