.fitness-timeline-edit {
  background: $colour-background-light;
  box-sizing: border-box;
  display: inline-block;
  padding: 1rem;
  position: absolute;
  left: -1px;
  top: calc(100% + 2px);
  width: 300px;
  z-index: 9999;

  p {
    margin: 0 0 1rem 0;
  }
}

.fitness-timeline-edit__controls {
  cursor: pointer;
  margin-bottom: 1rem;
  position: absolute;
  right: 12px;
  top: 14px;
  text-align: right;

  .icon {
    fill: $colour-accent;
    height: 1.5rem;
    margin-left: .75rem;
    width: 1.5rem;

    &:hover {
      fill: $colour-accent-alt;
    }
  }
}

.fitness-timeline-edit__title {
  padding-right: 60px;
}

.fitness-timeline-edit__duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  user-select: none;
  .icon-wrapper {
    .icon,
    .icon.icon-remove-circle {
      color: $colour-accent;
      cursor: pointer;
      height: 30px;
      width: 30px;

      &:hover {
        fill: $colour-accent-alt;
      }
    }
  }
}
