.fitness-timeline-heading {
  font-size: 11px;
  margin-top: 1rem;
  min-width: 1100px;
  user-select: none;
}

.fitness-timeline-heading__section {
  display: inline-block;
  border-left: 1px solid #193c52;
  box-sizing: border-box;
  padding-left: 6px;
  padding-bottom: 6px;
}
