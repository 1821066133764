.playlist-preview {
  background: $colour-background-mid;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h3 {
    margin: 0;
  }
  .preview-header{
    padding:15px 12px;
    display: flex;
    .playlist-preview__image {
      float: left;
      margin: 0 1rem 1rem 0;
      width: 170px;
    }
    .playlist-preview__details{
      .playlist-preview__actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .playlist-preview__actions_right{
          display: flex;
          .icon{
            width:33px;
            height: 33px;
            margin:0 12px;
          }
        }
      }
    }
    .playlist-preview__close {
      cursor: pointer;
      font-size: 11px;
      position: absolute;
      top: 0;
      right: 16px;
      .playlist-preview__close-icon {
        display: inline-block;
        margin-right: .25rem;
        vertical-align: bottom;
      
        .icon-wrapper .icon {
          color: #fff;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.playlist-preview_subscribe-btn {
  background: $colour-submit;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: .25rem .5rem;
  float: right;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}

.playlist-preview__tabs {
  border-bottom: 1px solid $colour-background-light;
  display: flex;
  flex-shrink: 0;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.playlist-preview__tab {
  color: $colour-text-weak;
  cursor: pointer;
  margin-bottom: -3px;
  padding: .5rem 1rem;

  &:hover {
    border-bottom: 2px solid $colour-text-weak;
  }
}

.playlist-preview__tab--active {
  border-bottom: 2px solid $colour-accent;
  color: $colour-text;
}

.playlist-preview__tracklist {
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.playlist-preview__tracklist-item {
  display: flex;
  border-bottom: 1px solid $colour-background-light;
  margin-bottom: .5rem;
  padding-bottom: .5rem;

  &:last-child {
    border: none;
  }
}

.playlist-preview__tracklist-play {
  cursor: pointer;
  margin-right: 1rem;
  padding-top: 2px;

  .icon-wrapper .icon {
    color: $colour-text-link;
  }
}

.playlist-preview__tracklist-track {
  flex: 1;
}

.playlist-preview__tracklist-track-name {
  color: $colour-text;
  font-size: .9rem;
}

.playlist-preview__tracklist-artist {
  color: $colour-text-mid;
  font-size: .75rem;
}

.playlist-preview__tracklist-duration {
  padding-right: 1rem;
  font-size: 12px;
}

.playlist-preview__data {
  display: flex;
  font-size: 1rem;
  overflow: auto;
  height: 100%;
  padding: 2rem;
}

.playlist-preview__data-details {
  width: 40%;

  p {
    color: $colour-accent;
  }
}

.playlist-preview__data-genres {
  margin-top: -50px;
  width: 60%;
}

.playlist-preview__data-icon {
  display: inline-block;
  margin-right: .5rem;
  position: relative;
  top: 5px;

  .icon-wrapper .icon {
    color: $colour-accent;
    height: 20px;
    width: 20px;
  }
}

.playlist-preview__data-tags {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    border: 1px solid $colour-accent-alt;
    color: $colour-accent-alt;
    border-radius: 6px;
    font-size: .75rem;
    margin: 0 .5rem .5rem 0;
    padding: 4px 8px;
  }
}
.playlist-preview{
  justify-content:space-between;
  overflow: hidden;
  .tracks-wrapper{
    width:100%;
    overflow: scroll;
    .tracklist {
      list-style-type: none;
      margin: 0;
      overflow: auto;
      padding: 0;
    }

    .tracklist-item {
      display: flex;
      position: relative;
      border-bottom: 1px solid $colour-background-light;
      padding:.5rem 12px .5rem 12px;
      justify-content: center;
      align-items: center;

      &--playing{
        background-color: $colour-text-link;

        .icon-play{
          color:#ffffff;
        }
      }
      &:last-child {
        border: none;
      }
      .icon-play{
        margin-right: 1rem;
        padding-top: 2px;
      }
      .tracklist-track {
        flex: 1;
        .tracklist-track-name {
          color: $colour-text;
          font-size: .9rem;
        }

        .tracklist-artist {
          color: $colour-text-mid;
          font-size: .75rem;
        }
      }
      .tracklist-meta{
        display: flex;
        flex-direction: row;
        width:100px;
        justify-content:flex-end;
        .tracklist-duration {
          font-size: 12px;
          min-width: 33px;
        }
        .energy{
          font-size: 12px;
        }
        .tracklist-new{
          font-size: 12px;
        }
      }
    }
  }
}
