.input-wrapper{
  position: relative;
  margin: 15px 0;
  .input{
    display: block;
    border:none;
    outline: none;
    background-color: transparent;
    width:100%;
    padding:7px 0px;
    color: $colour-text; 
    border-bottom: 1px solid $colour-background-mid;
  }
  .focus-border{
    position: absolute; 
    bottom: 0; 
    left: 0; 
    width: 0; 
    height: 1px; 
    background-color: $colour-background-light; 
    transition: 0.3s;
  }
  .input-label{
    position: absolute;
    left: 0;
    width: 100%; 
    top: 9px; 
    color: $colour-text; 
    transition: 0.3s; 
    outline: none;
    pointer-events: none;
    letter-spacing: 0.5px;
    font-size: 12px;
  }
  .input:focus ~ .focus-border,
  .input:not([value=""]) ~ .focus-border,
  .input:-webkit-autofill ~ .focus-border{
    width: 100%;
    transition: 0.3s;
  }
  
  .input:focus ~ .input-label, 
  .input:not([value=""]) ~ .input-label,
  .input:-webkit-autofill ~ .input-label{
    top: -16px;
    font-size: 12px;
    color: $colour-text-link;
  }
  .input:-webkit-autofill{
    box-shadow: 0 0 0 1000px $colour-background inset;
    -webkit-text-fill-color: $colour-text;
  }
  .input::-webkit-input-placeholder {
    color: $colour-text;
  }
}