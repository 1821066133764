.container-venueAnnouncements{
  margin: 0 20px;
  .announcements-actions{
    height:50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border:1px solid $colour-background-light;
    background-color: $colour-background-mid;
    border-bottom: none;
    display:flex;
    align-items: center;
    padding:5px;
    .announcements-actions-left{
      min-width: 50%;
    }
    .announcements-actions-right{
      min-width:50%;
      display: flex;
      justify-content: flex-end;
    }
    .icon{
      width:25px;
      height:25px;
      margin:0 10px;
    }
  }
  .announcements-header{
    display: flex;
    height: 35px;
    justify-content: space-around;
    align-items: center;
    background-color: $colour-background-mid;
    border:1px solid $colour-background-light;
    border-right:3px solid $colour-background-light;
    width:100%;
    .announcements-header-day{
      border-left:1px solid $colour-background-light;
      text-align: center;
      width: 14.28%;
      font-size: 12px;
    }
  }
  .announcements-wrapper{
    position: relative;
    background-color: $colour-background-mid;
    border-top:0;
    height: 100%;
    overflow: scroll;
    margin-bottom: 80px;
    .announcement{
      position: absolute;
      width:14.28%;
      font-size: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $colour-background-light;
      opacity: 0.8;
      border:1px solid $colour-background;
      padding-left:2px;
      border-radius: 3px;
      cursor: pointer;
      height:70px;
      &:hover{
        border:1px solid $colour-accent-alt;
      }
      .controls{
        display: flex;
        align-items: center;
        font-size: 20px;
        .icon{
          width:25px;
          height:25px;
        }
      }
    }
    .time-row{
      font-size: 12px;
      height:30px;
      border-left:1px solid $colour-background-light;
      border-bottom:1px solid $colour-background-light;
      display:flex;
      justify-content: space-around;
      align-items: center;
      .time{
        width: 9%;
        text-align: center;
      }
      .time-grid{
        width: 13%;
        text-align: center;
        border-left:1px solid $colour-background-light;
        .icon{
          width:18px;
          height:18px;
        }
      }
      &:hover{
        background-color: $colour-background;
      }
    }
  }
}