.overlay-wrapper{
  //display: none !important;
  position: absolute;
  width: 100%;
  height:100%;
  display: none;
  background-color: rgba($colour-background-mid,0.9);
  z-index: 999;
  .container-overlay{
    z-index: 1000;
    position: absolute !important;
    top: 15%;
    left:20%;
    width:60%;
    height:70%;
    background-color: $colour-background-mid;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    padding:10px;
    overflow: hidden;
    .overlay-loader-wrapper{
      min-height: 300px;
    }
    h1, h2{
      font-size: 15px;
      padding:5px 0;
      margin:0;
      border-bottom: 1px solid $colour-accent-alt;
    }
    .overlay-close{
      position: absolute;
      right:15px;
      top:15px;
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      z-index: 9;
    }
  }
  &.active{
    display:initial;
  }
  &.large{
    .container-overlay{
      top: 10%;
      left:10%;
      width:80%;
      height:80%;
    }
  }
  &.auto-height{
    .container-overlay{
      height: auto;
    }
  }
  &.importer{
    .container-overlay{
      top: 5%;
      left:10%;
      width:80%;
      height:80%;
    }
    
  }
}
