.schedule-creator__overlay {
  background: rgba(0 ,0 ,0 , .75);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
}

.schedule-creator {
  background: $colour-background;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;

  h3, h4 {
    margin: 0 0 .5rem 0;
  }
}

.schedule-creator__close-btn {
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 1rem;
  top: 0;
}

.schedule-creator__playlist-select {
  color: $colour-background;
  font-size: 14px;
  margin-bottom: 1rem;
  width: 100%;
}

.schedule-creator__repeat-select {
  color: $colour-background;
  width: 100%;
}

.schedule-creator__time-select {
  color: $colour-background;
}

.schedule-creator__time-select-separator {
  margin: 0 10px;
}

.schedule-creator__fill-gap {
  margin-left: 1rem;
}

.schedule-creator__time {
  display: flex;
  margin-top: 1rem;
}

.schedule-creator__time-from {
  margin-right: 1rem;
  width: 50%;
}

.schedule-creator__time-till {
  width: 50%;
}

.schedule-creator__time-close {
  cursor: pointer;
  float: left;
  clear: both;
  width: 100%;
  padding: .5rem;
  margin: .5rem 0 0 0;
  border-top: 1px solid black;
}

.schedule-creator__submit-btn-container {
  text-align: right;
}

.schedule-creator__submit-btn {
  background: $colour-submit;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  padding: .25rem .5rem;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}

.schedule-creator__delete-btn {
  background: $colour-error ;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  padding: .25rem .5rem;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}
