.table-actions-library{
  flex-wrap:wrap;
  //min-height: 75px !important;
  padding:0 10px !important;
  .table-actions-left{
    .icon-funnel{
      cursor: pointer;
      width:25px;
      height:25px;
      margin:0 5px;
    }
  }
  .table-actions-right{
    .icon{
      transform: scale(0);
      transition: 200ms all;
      margin:0 10px !important;
    }
    &.active{
      .icon{
        transform: scale(1);
      }
    }
  }
  .metadata{
    width:100%;
    padding: 8px 0;
  }
}
