.loader-wrapper{
  position: absolute;
  top:50%;
  left:50%;
  margin-left:-18px;
  margin-top:-18px;
  img {
    width:20px;
    height:20px;
  }
}