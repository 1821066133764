.container-venues-wrapper{
  .container-venues {
    flex-direction: row !important;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: self-start;

    .form-venues {
      padding-bottom: 30px;
    }
  }

  .container-venues.maxHeight {
    &.grid-view,
    &.table-view {
      max-height: calc(100vh - 240px);
    }
  }
}

.container-filter-wrapper {
  align-items: center;
  background-color: $colour-background-mid;
  margin: 0 auto 10px auto;
  padding: 3px;
  width: calc(100% - 40px);

  .icon {
    cursor: pointer;
    height: 40px;
    margin: 0 9px;
    width: 40px;
  }
}
