.table-row-empty{
  &:hover{
    td{
      background-color: $colour-background-mid !important;
    }
  }
  td{
    text-align: center;
    height: 0;
    border: none;
  }
}
