.container-overlayPlaylistsAddTrack,
.container-overlaySubscribedPlaylistToVenues{
  h1{
    font-size: 15px;
    margin: 10px 10px 30px 10px;
  }
  .playlists-overlay-wrapper{
    height:100%;
    overflow-y:scroll;
    .playlists-overlay{
      margin: 10px;
      padding: 10px 20px;
      border-radius:4px;
      border:1px solid $colour-text-link;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-wrapper{
        .icon{
          color:$colour-success;
        }
      }
      &:hover{
        border:1px solid $colour-accent;
        background-color: $colour-background-light;
      }
      &.assigned{
        border:1px solid $colour-success;
      }
    }

    .playlists-overlay__already-has-tracks {
      margin: 10px 20px;
    }
  }
}
