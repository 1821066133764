.filter-wrapper{
  position: relative;
  font-size: 13px;
  width:100%;
  padding:0 20px;
  margin:3px 0;
  .icon-ios-search{
    position: absolute;
    width:20px;
    height:20px;
    left: -10px;
    top: 7px;
  }
  .filter{
    width:100%;
    outline: 0;
    border:0;
    padding:7px 5px 7px 25px;
    width:100%;
    color: $colour-text;
    background-color: $colour-background-mid;
    &::placeholder{
      color: $colour-text;
    }
  }
}
