.badge{
  margin:0 5px;
  background-color: darken($colour-accent,10);
  font-size: 12px;
  padding:0px 4px;
  border-radius: 4px;
  &.badge-action{
    background-color: $colour-text-link;
    cursor: pointer;
  }
}
