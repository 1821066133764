.progress-tracker {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.progress-tracker__step {
  align-items: center;
  display: flex;
}

.progress-tracker__step-number {
  background: $colour-text-disabled;
  border-radius: 16px;
  color: $colour-primary;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: background 2s;
  width: 32px;

  .icon-wrapper .icon {
    color: $colour-primary;
  }
}

.progress-tracker__step-text {
  color: $colour-text-disabled;
  transition: color 2s;
}

.progress-tracker__step--active {
  .progress-tracker__step-number {
    background: white;
    color: $colour-primary;
  }

  .progress-tracker__step-text {
    color: white;
    transition: color 2s;
  }
}

.progress-tracker__step--complete {
  .progress-tracker__step-number {
    background: $colour-accent;
  }

  .progress-tracker__step-text {
    color: $colour-accent;
  }
}

.progress-tracker__step--final {
  justify-content: flex-end;
}

.progress-tracker__divider {
  background: $colour-text-disabled;
  flex-grow: 1;
  margin: 0 1rem;
  height: 1px;
  transition: background 2s;
}

.progress-tracker__divider--active {
  background: $colour-accent;
}
