.container-venue-controls{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50%;
  padding-bottom: 1em;

  .container-play-controls{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 100%;
    width: 100%;

    .icon{
      width: 3em;
      height: 3em;
      margin: 0 15px;
    }
    .icon-play-pause{
      width: 4em;
      height: 4em;
    }
  }

  .container-volume-wrapper{
    display: flex;
    align-items:center;
    flex-basis: auto;
    padding: 0;
    position:relative;
    flex-grow: 1;
    height: 100%;
    width: 80%;

    .volume{
      flex-grow:1;
      width:20px;
      height:20px;
      margin:0 20px;
      fill: $colour-text-mid;
    }

    .icon{
      width: 4vw;
      height: 4vh;
      &.icon-volume-high{
        margin-left:10px;
      }

    }

    #volume-track-container{
      flex-grow:4;
      width:100%;
      display: flex;
      align-items: center;
      #volume-track{
        position: relative;
        flex-grow:4;
        width:100%;
        height:4px;
        pointer-events: none;
        #volume-thumb{
          width:20px;
          height:20px;
          background:$colour-background;
          position:absolute;
          top: -8px;
          border-radius:20px;
          right:50%;
          border:3px solid $colour-accent-alt;
          pointer-events: auto;
          cursor:pointer;
        }
      }
    }
  }
  .display-restart{
    display: flex;
    align-items: center;
    font-size: .8em;

    .icon{
      width:30px;
      height:30px;
    }
  }
}

.venue-controls-wrapper--disabled {
  opacity: .2;
  pointer-events: none;
}

.venue-controls {
  .venue-controls__player {
    background: #112938;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #193c52;
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2vh;
  }

  .venue-controls__player-disabled p {
    font-size: .8em;
    text-align: center;
  }

  .icon-refresh {
    cursor: pointer;
    height: 60px;
    width: 60px;
  }
}

