.radio-group{
  margin: 12px 0;
  h1{
    margin: 6px 0 18px 0!important;
  }
  .radio-button{
    margin:3px 0 3px 24px;
    input{
      margin-right: 12px;
    }
    .label{

    }
    .info{
      margin-left:12px;
      font-size: 15px;
    }
  }
}