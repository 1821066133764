.icon-wrapper{
  position: relative;
  display: flex;
  justify-content: center;
  .icon{
    color:$colour-text-weak;

    &.icon-remove-circle,
    &.icon-trash {
      color: $colour-error;
    }
    &.icon-success{
      color: $colour-success;
    }
  }
  &:not(.icon-disabled):hover{
    .icon-action{
      color:$colour-accent-alt;
      animation-name: bounceIn;
      animation-duration: 300ms;
      animation-iteration-count: 1;
    }
  }
  &.icon-disabled{
    .icon{
      cursor: not-allowed;
      color:$colour-background;
    }
  }
  @keyframes bounceIn{
    0%{
      opacity: 0.5;
      transform: scale(1) translate3d(0,0,0);
    }
    50%{
      opacity: 0.9;
      transform: scale(1.1);
    }
    80%{
      opacity: 1;
      transform: scale(0.89);
    }
    100%{
      opacity: 1;
      transform: scale(1) translate3d(0,0,0);
    }
  }
}
