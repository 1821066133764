.container-overlayVenueScheduleCopy{
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 10px 10px 10px;
    span{
      font-size: 15px;
    }
    .select-deselect{
      display: inline;
    }
  }
  .venues-overlay-wrapper{
    height:100%;
    overflow-y:scroll;
    .venue-overlay{
      margin: 10px;
      padding: 10px 20px;
      border-radius:4px;
      border:1px solid $colour-text-link;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-wrapper{
        .icon{
          color:$colour-success;
        }
      }
      &:hover{
        border:1px solid $colour-accent;
        background-color: $colour-background-light;
      }
      &.selected:not(:hover){
        border:1px solid $colour-success;
      }
    }
  }
}
