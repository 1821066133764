.container-playlist{
  .container-tabs{
    &.library{
      margin-bottom:0;
    }
  }
  .container-isPlaylist{
    .container.container-table.maxHeight {
      max-height: calc(100vh - 308px);
    }
    .container-tabs{
      margin:0;
      .tabs-wrapper{
        margin:0 0 3px 0;
      }
    }
  }
}
