.container-last-played{
  flex-grow: 1;
  overflow-x: hidden !important;
  .list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:20px 0;
    margin:5px;
    border-bottom:1px solid $colour-background-light;
    font-size: 0.8rem;
    .track-actions{
      min-width: 40px;
      text-align: center;
      .icon{
        cursor: pointer;
        width:20px;
        height:20px;

        &.icon-close-circle {
          color: $colour-error;
        }
      }

      .list-item__loader {
        display: flex;
        justify-content: center;

        .loader-wrapper {
          margin: 0;
          position: relative;
          width: 20px;
          height: 20px;
          left: auto;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .list-item--blocked {
    color: $colour-text-weak;
    text-decoration: line-through;
  }
}
