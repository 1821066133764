.container-venue-display {
  display: flex;
  align-items: center;
  padding: .5em 0;
  height: 50%;

  .display-header {
    font-size: 1.125em;
  }

  .display-track, .display-artist {
    color:$colour-accent-alt;
    font-size: .9em;
  }

  .display-playlist, .display-ends {
    font-size: .75em;
    color:$colour-accent;
  }

  div {
    padding: .25em .5em;
    text-align: center;
  }
}
.container-venue-display-not-connected {
  align-items: center;
  .icon{
    width:60px;
    height:60px;
  }
}
