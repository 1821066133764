.queue-item{
  display: flex;
  padding:10px 0;
  justify-content: space-between;
  align-items: center;
  &:hover{
    background-color: $colour-background-light;
  }
  .play{
    margin-left:10px;
  }
  .title{
    width: 25%;
  }
  .artist{
    width: 25%;
  }
  .album{
    width: 20%;
  }
  .length{
    width:10%;
  }
  .actions{
    width:40px;
  }
}