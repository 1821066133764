.container-importer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:0 20px 130px 20px;

  p {
    color:white;
  }

  .importer-login {
    width:100%;
    text-align: center;
  }

  .importer-playlists-container {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;

    .playlists-wrapper {
      position: relative;
      height: 100%;
      overflow-y: scroll;

      .playlist {
        width:320px;
        cursor: pointer;
        padding:10px 20px;
        margin:5px 0;
        border:1px solid $colour-background-light;
        border-radius: 5px;

        &:hover {
          border:1px solid $colour-text-link;
        }

        &.active {
          border:1px solid $colour-accent-alt;
          background-color: $colour-text-link;
        }
      }
    }

    button {
      width:320px;
    }
  }

  .importer-tracks {
    width:100%;
  }
}