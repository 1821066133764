.interleave-creator {
  .interleave-creator__ratio {
    margin: 1rem 0 0 0;

  }

  .typeahead-result {
    .loader-wrapper {
      display: inline-block;
      height: 16px;
      margin: 0 .5rem 0 0;
      position: relative;
      top: -1px;
      left: 0;
      width: 16px;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.interleave-creator__ratio-select {
  color: $colour-background;
  font-size: 14px;
  margin: 0 10px;
  width: auto;
}
