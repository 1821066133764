.interleave-ratio-changer-overlay {
  background: rgba(0 ,0 ,0 , .75);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
  .interleave-ratio-changer-wrapper{
    background: $colour-background;
    padding: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    h1{
      font-size: 18px;
      margin:0 0 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .interleave-ratio-changer-controls{
      display: flex;
      justify-content:space-between;
      align-items: center;
      select{
        color: #000;
        height:30px;
      }
      button{
        background: #4ba0b3;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        padding: .25rem .5rem;
        outline: none;
        &:hover {
          border: 1px solid #fff;
        }
      }
    }
  }
}