.queue-overlay-item {
  background: $colour-background-mid;
  cursor: pointer;
  display: flex;
  position: relative;
  border-bottom: 1px solid $colour-background-light;
  padding: .5rem;

  &:hover {
    background: $colour-background-light;
  }

  &:last-child {
    border: none;
  }

  &--playing,
  &--playing:hover {
    background: $colour-text-link;
  } 

  .icon-queue-overlay-item__play {
    margin-right: 1rem;
    padding-top: 2px;
  }

  .queue-overlay-item__track {
    flex: 1;
    padding-right: 10px;

    .queue-overlay-item__name {
      color: $colour-text;
      font-size: .9rem;
    }

    .queue-overlay-item__artist {
      color: $colour-text-mid;
      font-size: .75rem;
    }
  }

  .queue-overlay-item__meta {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    width: 80px;
    justify-content:space-between;

    .queue-overlay-item__energy {
      width: 20px;
    }

    .icon-close {
      font-size: 16px;
    }
  }
  &--playing{
    .icon-wrapper{
      .icon{
        color: #ffffff;
      }
    }
    .queue-overlay-item__track{
      .queue-overlay-item__artist{
        color: #ffffff;
      }
    }
    .queue-overlay-item__meta{
      .queue-overlay-item__energy{
        .energy-number{
          color:#ffffff;
        }
        .icon-wrapper{
          .icon{
            color: #ffffff;
          }
        }
      }
    }
  }
}
