.queue-overlay__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 70px);
  width: 100vw;
}

.queue-overlay {
  background: $colour-background-mid;
  box-shadow: -20px -20px 30px 0px rgba(0, 0, 0, 0.5);
  height: 500px;
  overflow: auto;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35%;
}

.queue-overlay__header {
  background: $colour-background-light;
  border-bottom: 1px solid $colour-accent;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  padding: .5rem;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .queue-overlay__loop-btn {
    .icon {
      cursor: pointer;
      height: 26px;
      margin-right: 1rem;
      width: 26px;
    }

    &--active {
      .icon {
        color: $colour-accent-alt;
      }
    }
  }

  .queue-overlay__submit-btn {
    background: #4ba0b3;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 1rem 0 0;
    padding: .25rem .5rem;
    outline: none;

    &:hover {
      border: 1px solid #fff;
    }
  }

  .queue-overlay__delete-btn {
    background: $colour-error ;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    padding: .25rem .5rem;
    outline: none;

    &:hover {
      border: 1px solid #fff;
    }
  }
}

.queue-overlay__tracks {
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.queue-overlay__empty-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
}
