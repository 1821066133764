.dashboard-venue-map__loader {
  height: 100%;
}

.dashboard-venue-map__container {
  height: 400px;
  padding: 1rem;
}

.dashboard-venue-map {
  height: 100%;
}
