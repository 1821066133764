.waveform{
  position: relative;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:60%;
  .waveform-horizon{
    position: absolute;
    display: block;
    width:100%;
    height:1px;
    top:50%;
    left:0;
    background-color: $colour-accent;
  }
}