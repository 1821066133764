.online-indicator{
  width:44px;
  height:44px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .offline{
    width:20px;
    height:20px;
    border-radius: 20px;
    background-color: $colour-error;
  }
}