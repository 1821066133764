.form-tagger-wrapper{
  position: relative;
  .icon-ios-close{
    display: none;
    position: absolute;
    width:30px;
    height:30px;
    right: 10px;
    top: 2px;
    &.active{
      display: inline;
    }
  }
  .form-tagger-input{
    width:100%;
    outline: 0;
    border:0;
    padding:17px;
    width:100%;
    height:25px;
    color: $colour-text;
    background-color: $colour-background-light;
    &::placeholder{
      color: $colour-text;
    }
  }
  .form-tagger-input-results{
    position: absolute;
    top: 40px;
    left:0px;
    z-index: 999;
    background-color:$colour-background-light;
    width:100%;
    max-height: 66vh;
    overflow-y: scroll;
    height: 0;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    &.form-tagger-input-results-active{
      height:auto;
      border: 1px solid $colour-background;
    }
    .form-tagger-input-result{
      display: block;
      margin:0;
      padding:10px 10px;
      text-decoration: none;
      color:$colour-text;
      &:hover{
        cursor: pointer;
        background-color:$colour-accent-alt;
        color: $colour-text-strong-invert;
      }
    }
  }
  .form-tagger-tags{
    margin:20px;
    .form-tag{
      display: inline;
      margin:0 5px;
    }
  }
}