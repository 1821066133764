.form-select-wrapper{
  margin: 15px 0;
  .form-select{
    background-color: $colour-background-light;
    outline: none;
    border:1px solid $colour-background-mid;
    padding:10px;
    option{
      color: $colour-text;
    }
  }
}