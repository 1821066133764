.table-head{
  .table-head-row{
    height:40px;
    .table-head-cell{
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      vertical-align: top;
      font-size: 13px;
      padding:10px;
      text-align: left;
      font-weight: 100;
      background-color: $colour-background-light;
      text-transform: uppercase;
      color:$colour-text;
      box-shadow: inset 0px -1px 0px 0px $colour-accent;
      z-index: 99;
      &.table-head-cell-checkbox{
        input{
          margin-left: 9px;
        }
      }
      &.table-head-cell-actions{
        text-align: center;
        width:120px;
      }
      &:hover{
        color:$colour-accent;
      }
      &.table-head-cell-checkbox{
        width: 40px;
      }
      &.table-head-cell-play{
        width: 40px;
      }
      &.table-head-cell-small{
        width:90px;
        text-align: center;
      }
      &.table-head-cell-normal{
        width:100px;
      }
      &.table-head-cell-large{
        width:160px;
      }
      .icon-wrapper{
        cursor: pointer;
        float: right;
      }
      &.active{
        .icon{
          color:$colour-accent-alt;
        }
      }
    }
    &th:first-child{
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }
}
