.onboarding-bubbles {
  height: 100%;
  margin: 0 auto;
  padding-bottom: 200px;
  max-width: 1600px;
  width: 100%;
}

.button.onboarding-bubbles__button {
  margin: 0 0 0 1rem;
  font-size: 14px;
}
