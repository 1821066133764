.container-library-table-wrapper{
  .table-actions-library{
    .table-actions-left{
      .typeahead-wrapper-library{
        .typeahead-results-library{
          left:-11px;
        }
      }
    }
  }
  .container-table.maxHeight {
    max-height: calc(100vh - 285px);

    .table{
      tbody{
        tr{
          .table-cell-library-action{
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
