.container-venueSchedule{
  .schedule__no-playlists-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .typeahead-results-interleavePlaylists{
    max-height: 36vh !important;
  }
  .schedule-actions{
    height:50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border:1px solid $colour-background-light;
    background-color: $colour-background-mid;
    border-bottom: none;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:5px;

    .schedule-actions-left {
      align-items: center;
      display: flex;

      select {
        cursor: pointer;
      }

      .radio {
        margin-left: 1rem;
      }
    }

    .schedule-actions-calendar {
      align-items: center;
      display: flex;
    }

    .schedule-actions-right{
      display: flex;
      justify-content: flex-end;
    }

    .icon{
      cursor: pointer;
      width:25px;
      height:25px;
      margin:0 10px;
    }
  }
  .schedule-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $colour-background-mid;
    border:1px solid $colour-background-light;
    border-right:3px solid $colour-background-light;
    width:100%;

    .schedule-header-day {
      border-left:1px solid $colour-background-light;
      padding: 6px 0;
      text-align: center;
      width: 13%;
      font-size: 12px;

      &--active {
        background-color: $colour-accent;
      }
    }

    .schedule-header-time {
      width: 9%;
    }
  }
  .schedules-wrapper{
    position: relative;
    background-color: $colour-background-mid;
    border-top:0;
    overflow: auto;
    &--loading {
      opacity: .4;
    }

    .schedule {
      height: 720px;
      overflow: hidden;
      position: absolute;
      left: 9%;
      top: 0;
      width: 91%;
      pointer-events: none;
    }

    .schedule__item {
      position: absolute;
      width: 13%;
      font-size: 10px;
      display: block;
      align-items: center;
      background-color: $colour-background-light;
      border: 1px solid $colour-background;
      padding-left: 2px;
      border-radius: 3px;
      cursor: move;
      pointer-events: all;
      z-index: 10;
      &:hover {
        z-index:11;
        border: 1px solid $colour-accent-alt;
      }
    }

    .schedule__item--active {
      background: $colour-background;
      border: 1px solid $colour-accent-alt;
      opacity: 1;
      z-index: 100;
    }

    .schedule__item--interleave {
      cursor: pointer;
    }

    .schedule__item--active-interleave {
      background: $colour-text-link;
      color: $colour-text;
      opacity: 1;
    }

    .schedule__item--for-edit {
      background: $colour-accent;
      border: 1px solid $colour-background;
      opacity: 1;
      z-index: 90;
    }

    .schedule__item--disabled {
      background: rgba(47,54,64,.75);
      border: 1px solid #000;
      color: $colour-text-weak;
      pointer-events: none;
      z-index: 0;
    }

    .schedule__item-name {
      margin: 0;
      padding: 0 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      p{
        margin:0;
        padding:0;
        font-size: 13px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.interleave-name{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .icon-wrapper{
          margin-right:2px;
          .icon{
            color: #ffffff;
          }
        }
      }
    }

    .time-row{
      font-size: 12px;
      height:30px;
      border-left:1px solid $colour-background-light;
      border-bottom:1px solid $colour-background-light;
      display:flex;
      justify-content: space-around;
      align-items: center;
      .time{
        cursor: pointer;
        width: 9%;
        text-align: center;
      }
      .time-grid{
        cursor: pointer;
        width: 13%;
        text-align: center;
        border-left:1px solid $colour-background-light;
        .icon{
          width:18px;
          height:18px;
        }
      }
      &:hover{
        background-color: $colour-background;
      }

      &--interleave {
        .time,
        .time-grid{
          cursor: default;
        }
      }
    }
  }

  .typeahead-wrapper {
    padding: 0;

    .typeahead-input {
      padding-left: 35px;
    }

    .icon-ios-search {
      left: 6px;
    }
  }
}

.react-resizable-handle {
  cursor: ns-resize;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
}

.react-calendar {
  font-family: "Walsheim", sans-serif !important;
  position: absolute;
  top: 3rem;
  color: $colour-background;
  z-index: 250;

  .react-calendar__tile--active {
    background: $colour-accent;

    &:hover {
      background: $colour-background !important ;
    }
  }
}
