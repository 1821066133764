.container-overlayVenueCalendarCopy {
  min-height: 300px;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 10px 10px 10px;
    span{
      font-size: 15px;
    }
    .select-deselect{
      display: inline;
    }
  }
  .venues-overlay-wrapper{
    height:100%;
    overflow-y:scroll;
    .venue-overlay{
      margin: 10px;
      padding: 10px 20px;
      border-radius:4px;
      border:1px solid $colour-text-link;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-wrapper{
        .icon{
          color:$colour-success;
        }
      }
      &:hover{
        border:1px solid $colour-accent;
        background-color: $colour-background-light;
      }
      &.selected:not(:hover){
        border:1px solid $colour-success;
      }
    }
  }

  .overlay-venue-calendar-copy__date-section {
    align-items: center;
    display: flex;
    margin: 1rem 0;
    overflow: visible;
    position: relative;

    .overlay-venue-calendar-copy__date-select {
      width: 50%;

      &:first-child {
        margin-right: 1rem;

        .react-calendar {
          left: 0;
        }
      }

      &:last-child {
        margin-left: 1rem;

        .react-calendar {
          right: 0;
        }
      }

      input {
        background-color: $colour-background;
        border:none;
        color: $colour-text;
        cursor: pointer;
        display: block;
        outline: none;
        padding:7px;
        user-select: none;
        width:100%;
      }

      .react-calendar {
        top: -320px;
      }
    }
  }
}
