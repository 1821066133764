.container {
  .container-venue-controls-playlists{
    overflow-x: hidden !important;

    .list-item{
      display: flex;
      align-items: center;
      padding:15px 10px;
      margin: 5px;
      min-height:60px;
      border-bottom:1px solid $colour-background-light;
      .icon{
        width:20px;
        height: 20px;
        margin:0 10px;
      }
      .title{
        font-size: 14px;
      }
      .tracks{
        font-size: 12px;
        color:$colour-accent-alt;
      }
    }
  }
}
