.container-playlistsMain{
  .typeahead-wrapper-playlistsMain{
    padding:0;
    .icon-ios-search{
      left:10px;
      top:12px;
    }
    .typeahead-input-playlistsMain{
      padding: 12px 5px 12px 40px;
    }
  }
}