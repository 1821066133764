.col1 {
  width: 12.5%
}

.col2 {
  width: 25%;
}

.col3 {
  width: 33.3%;
}

.col4 {
  width: 50%;
}

.col5 {
  width: 66.7%;
}

.col6 {
  width: 75%;
}

.col7 {
  width: 100%;
}

.dashboard-unit__inner {
  background: $colour-background-mid;
  margin: .75rem;

  .recharts-wrapper {
    margin: auto;
  }
}

.dashboard-unit__header {
  border-bottom: 1px solid $colour-background;
  font-size: 16px;
  margin: 0;
  padding: .5rem 1rem;
  text-align: left;

  span {
    margin-right: .75rem;
  }
}

.dashboard-unit__no-data-message {
  padding: 0 1rem 1rem 1rem;
}
