@font-face {
  font-family: 'Walsheim';
  src: url('../../assets/fonts/GT-Walsheim-Light.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/GT-Walsheim-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/GT-Walsheim-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../assets/fonts/GT-Walsheim-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
$fontFamily:"Walsheim",sans-serif;
