.table-actions-playlistTracks{
  flex-wrap:wrap;
  min-height: 85px !important;
  .table-actions-right{
    .icon{
      transform: scale(0);
      transition: 200ms all;
    }
    &.active{
      .icon{
        transform: scale(1);
      }
    }
  }
  .metadata{
    width:100%;
    padding: 8px 0;
  }
}