.radio {
  display: flex;

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;

    label {
      margin-bottom: .5rem;
    }
  }

  h4 {
    margin-bottom: .5rem;
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 1rem;

    input {
      margin-right: .5rem;
    }
  }
}
