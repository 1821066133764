.library-search-filter__underlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 80;
}
.isPlaylist{
  .library-search-filter-wrapper{
    top:-60px;
  }  
}
.library-search-filter-wrapper{
  position: absolute;
  height:100%;
  width:640px;
  z-index: 999;
  padding-bottom: 150px;
  top:-40px;
  left:0;
  transform: translateX(-110%);
  transition:300ms ease-out;
  &.active{
    transform: translateX(0);
  }
  .library-search-filter{
    position: relative;
    width:100%;
    height:100%;
    padding:0 9px;
    overflow-x: scroll;
    background-color: $colour-background-mid;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
    padding-bottom: 80px;
    .header-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      h1{
        margin:0;
        font-size: 18px;
      }
      .icon-wrapper{
        .icon{
          width:21px;
          height: 21px;
        }
      }
    }
    .dropdown-selector{
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:9px 0;
      .icon-wrapper{
        margin-left:15px;
        .icon{
          width:18px;
          height:18px;
        }
      }
    }
    .panel{
      max-width:100%;
      height:100%;
      display: none;
      border:1px solid $colour-background-light;
      border-radius: 3px;
      padding:3px 0;
      &:hover{
        border:1px solid $colour-text-link;
      }
      &.active{
        display: flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-content: flex-start;
        height:auto;
      }
      .selector{
        position: relative;
        .icon-wrapper{
          position: absolute;
          right:6px;
          .icon{
            color:$colour-success;
          }
        }
        &:hover{
          border:1px solid $colour-text-link;
          background-color: $colour-background-light;
        }
        &.selected:not(:hover){
          border:1px solid $colour-success;
        }
        &.deselected:not(:hover){
          border:1px solid $colour-error;
        }
        &.deselected{
          .icon {
            color: $colour-error;
          }
        }
      }
    }

    .library-search-filter__genre-filter-type {
      display: flex;
      font-size: .8rem;
      margin-bottom: 1rem;

      label {
        align-items: center;
        display: flex;
        cursor: pointer;
        margin-left: .5rem;

        input {
          cursor: pointer;
          margin-right: .25rem;
        }

        span {
          line-height: 1rem;
        }
      }
    }

    .genres{
      .genre{
        width:23%;
        height:30px;
        border-radius: 3px;
        border:1px solid $colour-background-light;
        padding:3px 0;
        margin:3px 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
      }
    }
    .energies{
      .energy{
        width:8%;
        height:30px;
        border-radius: 3px;
        border:1px solid $colour-background-light;
        padding:3px 0;
        margin:3px 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .dates{
      align-items: center;
      justify-content: space-between;
      margin:9px 0;
      .divider{
        margin:0 33px;
      }
      .button{
        height:20px;
      }
      .icon-wrapper{
        margin:0 12px 0 0;
        .icon{
          width:21px;
          height:21px;
        }
      }

      .library-search-filter__calendar-underlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
      }

      .start-date, .end-date{
        cursor: pointer;
        display: flex;
        align-items: center;
        margin:0 1%;
        .date{
          min-width: 120px;
        }
        .icon-wrapper{
          margin:0 12px 0 0;
          .icon{
            width:30px;
            height:30px;
          }
        }
      }
    }
  }
  .actions-wrapper{
    background: $colour-background-mid;
    position: absolute;
    bottom: 153px;
    left:0;
    padding:0 9px;
    width:100%;
    display: flex;
    justify-content: space-between;
    .button{
      width:48%;
    }
  }
  .end-date {
    .react-calendar {
      right: 1rem;
    }
  }
}
