.schedule-playlist-overlay {
  background-color: $colour-background;
  border: 1px solid $colour-accent-alt;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  font-size: 1rem;
  overflow: visible;
  padding: 1rem;
  position: relative;
  width: 300px;
  z-index: 110;

  h3, h4 {
    margin: 0 0 .5rem 0;
  }

  p {
    margin: 0 0 1rem 0;
  }

  .schedule-playlist-overlay__playlist {
    padding-right: 2rem;
  }

  .schedule-playlist-overlay__delete {
    color: $colour-error;
    text-align: right;
    margin: 0;
    cursor: pointer;

    .icon-wrapper {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }

  .schedule-playlist-overlay__icons {
    display: flex;
    position: absolute;
    right: 8px;
    top: 14px;

    .icon-wrapper {
      cursor: pointer;
      height: 21px;
      width: 21px;

      .icon {
        height: 100%;
        width: 100%;
      }
    }
  }

  .schedule-playlist-overlay__secondary {
    color: $colour-text-weak;
  }
}
