
#energy-slider-wrapper{
  height:100%;
  max-height: 100%;
  display: flex;
  position:relative;
  align-items: center;
  width:100%;
  flex-direction:column;
  flex-grow:1;
  h2{
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 4px;
    border-bottom:1px solid $colour-background-light;
    margin:5px;
    padding:10px;
    text-align: center;
    width:100%;
  }
  input[type=checkbox]{
      height: 0;
      width: 0;
      visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 60%;
    max-width: 100px;
    height: 30px;
    background: $colour-background-light;
    display: block;
    border-radius: 100px;
    position: relative;
    transition: 0.5s;
  }

  label:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 90px;
      transition: 0.5s;
  }

  input:checked + label {
      background: #fd4b16;
      transition:0.5s;
  }

  input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
  }

  label:active:after {
      width: 130px;
  }
  .icon{
      flex-grow: 1;
      font-size: 30px;
      margin: 10px 0;
      fill:$colour-background-mid;
  }
  #energy-slider-track-container{
    flex-grow: 4;
    height:100%;
    width:100%;
    position: relative;
    display: flex;
    justify-content: center;
    #energy-slider-track{
      height:100%;
      width:3px;
      position: relative;
      background-color:$colour-background-mid;
      border-radius: 5px;
      pointer-events: none;
    }
    #energy-slider-thumb{
      position:absolute;
      height:30px;
      width:30px;
      background-color:$colour-background;
      border:3px solid $colour-background-mid;
      left:-13px;
      border-radius:20px;
      pointer-events: auto;
    }
  }
  &.active{
    .icon{
      &.low{
        color: #0071e2;
      }
      &.high{
        color:#fd4b16;
      }
    }
    #energy-slider-track-container{
      #energy-slider-track{
        background-image: linear-gradient(to top, #0071e2, #a058d3, #e52ea5, #ff1365, #fd4b16);
      }
      #energy-slider-thumb{
        border:3px solid #e52ea5;
        cursor:pointer;
      }
    }
  }
}