.playlist-item{
  padding:20px 0;
  border-top:1px solid $colour-background-mid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  &:hover{
    background-color: $colour-background-light;
    cursor: pointer;
    .playlist-item-actions {
      .button {
        border: 1px solid $colour-text-link;
      }
    }
  }
  .playlist-item-play{
    width:10%;
    max-width: 150px;
    margin:0 15px;
    position: relative;
    .image-wrapper{
      width: 100%;
      max-width: 150px;
      img{
        width: 100%;
      }
    }
    .icon-wrapper{
      position: absolute;
      bottom: calc(0% + 6px);
      left: calc(0% + 6px);
      .icon{
        width: 40px;
        height: 40px;
        color:#ffffff;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
      }
    }
  }
  .playlist-item-info{
    padding-right: .5rem;
    width:75%;
    .playlist-item-name{
      font-size: 23px;
    }
    .playlist-item-tracks{
      margin-left: 12px;
      color: $colour-accent-alt;
    }
    .playlist-item__subscribed {
      background: $colour-accent;
      border-radius: 3px;
      color: #fff;
      font-size: 11px;
      margin-left: 12px;
      padding: 3px;
    }
    .playlist-item-style{
      font-size: 14px;
    }
    .playlist-item-genres{
      font-size: 13px;
      text-transform: lowercase;
      color:$colour-text-link;
      .playlist-item-genre, .playlist-item-tag{
        margin:0 5px;
        &:first-child{
          margin-left:0;
        }
      }
    }
  }
  .playlist-item-actions{
    padding-right: 15px;
    width:15%;
    .icon{
      width: 40px;
      height: 40px;
    }
  }
}
