.container .container.container-fitnessTimeline {
  overflow: auto;
  padding-bottom: 70px;
}

.fitness-timeline__wrapper--disabled {
  opacity: .6;
  pointer-events: none;
}

.fitness-timeline {
  background-image: linear-gradient($colour-background-light 1px, transparent 1px), linear-gradient(90deg, $colour-background-light 0px, transparent 0px), linear-gradient($colour-background-light 1px, transparent 1px), linear-gradient(90deg, $colour-background-light 1px, transparent 1px);
  background-color: $colour-primary;
  background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  border: 1px solid $colour-background-light;
  height: 100px !important;
  user-select: none;
}

.fitness-timeline p {
  user-select: none;
}

.fitness-timeline__pane > span > div {
  display: none;
}

.fitness-timeline__pane > span > div:first-child {
  display: block;
  width: 10px !important;
}

.fitness-timeline__pane.fitness-timeline__pane--no-drag-handle > span > div:first-child {
  display: none;
}

.fitness-timeline__pane {
  border-right: 1px solid #193c52;
  cursor: pointer;
  overflow: visible;
}

.fitness-timeline-table {
  margin-top: 1rem;

  th, td {
    border: 1px solid #fff;
    padding: 1rem;
    text-align: left;
  }
}
