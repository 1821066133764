.playlistVenues.container.maxHeight {
  max-height: calc(100vh - 230px);
}


.container-playlist-venues{
  flex-direction: column;
  width:100%;

  .typeahead-wrapper-playlistVenues {
    padding: 0 20px;

    .icon-ios-search {
      left: 10px;
      top: 12px;
    }

    .typeahead-input-playlistVenues {
      padding: 12px 5px 12px 40px;
    }
  }

  .container-table{
    margin: 0 0 80px 20px !important;
  }
}
