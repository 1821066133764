.container-playlist-tracks{
  .table-cell-play{
    .icon-wrapper{
      .icon-alert{
        color: $colour-error;
      }
    }
  }
}
.playlistTracks.container.maxHeight {
  max-height: calc(100vh - 290px);
}
