/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;
  margin: 1rem 0;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 16px;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-arrow {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;

  .icon-wrapper .icon {
    color: $colour-text;
    height: 40px;
    width: 40px;
  }

  .slick-hidden {
    display: none;
  }
}

.slick-next {
  right: 0;
}

.playlists-carousel__playlist {
  outline: none;
}

.playlists-carousel__playlist-image {
  position: relative;
}

.playlists-carousel__playlist-name {
  background: $colour-background-mid;
  cursor: pointer;
  font-size: .9rem;
  padding: .5rem .25rem;
  min-height:60px;
}

.playlists-carousel__playlist-overlay {
  background: rgba(0, 0, 0, 0.75);
  font-size: .9rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: 150;

  &:hover,
  &--active{
    opacity: 1;
  }

  h4 {
    margin: .5rem;
  }
}

.playlists-carousel__playlist-overlay-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 307;

  .icon-wrapper .icon {
    color: $colour-text;
    height: 40px;
    width: 40px;

  }
}

.playlists-carousel__more-info-link {
  cursor: pointer;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
  text-decoration: underline;
}

.playlists-carousel__overlay {
  background: rgba(0,0,0,0.7);
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 300;
}

.playlists-carousel__overlay-inner {
  height: 70vh;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 308;
  width: 70vw;
}
