.container-overlayFitnessVenues-wrapper{
  margin-top:33px;
  .playlists-search-typeahead-wrapper{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: $colour-background;
    .typeahead-wrapper .typeahead-input{
      background-color: $colour-background;
    }
  }
  .table-head-cell-overlayFitnessVenues.table-head-cell-actions{
    width:18px !important;
  }
}