.table-cell{
  padding:10px;
  font-size: 14px;
  color: $colour-text;
  background-color: $colour-background-mid;
  border-bottom: 1px solid $colour-background-light;
  &:first-child{
    max-width: 150px;
  }
  &.table-cell-size-small,
  &.table-cell-checkbox {
    text-align: center;
  }
  &.table-cell-size-normal{
    min-width: 100px;
  }
  &.table-cell-play{
    .icon{
      cursor: pointer;
      width:20px;
      height:20px;
      vertical-align: inherit;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
  }
}
