.container-overlayPlaylistsLibrary{
  h1{
    margin-bottom: 12px !important;
  }
  .playlists-overlay-wrapper{
    overflow-y: scroll;
    .playlists-overlay{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:9px 6px;
      .icon{
        width:21px;
        height: 21px;
        &.icon-checkmark-circle{
          color: $colour-success;
        }
      }
      &:hover{
        background-color: $colour-background-light;
      }
    }
  }
}