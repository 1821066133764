.container-dashboard.container.maxHeight {
  max-height: calc(100vh - 135px);
}

.dashboard__pdf-loader-container {
  background: $colour-background;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
}

.dashboard__loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.dashboard {
  padding-top: 1rem;
}

.dashboard__loader-message {
  font-size: 24px;
  padding-bottom: 2rem
}

#dashboard__report-data {
  min-width: 1000px;
  position: relative;

  h2 svg {
    height: 30px;
    padding-right: 32px;
    width: 30px;
  }
}

.dashboard__row {
  display: flex;
  flex-wrap: wrap;
}

.dashboard__main {
  width: 100%;

  // Disabling during temp removal of album and playlist of the month
  //@media only screen and (min-width: 1280px) {
  //  width: 80%
  //}
}

.dashboard__main--pdf {
  background: #fff;
  color: $colour-background;
  width: 1200px;

  .dashboard-unit__inner {
    background: #fff;
  }

  h2 {
    margin: 20px;
  }
}

.dashboard__oe-pdf-logo {
  height: 25px;
  margin: 1rem 1rem 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
}

.dashboard__report-data-btn {
  background: $colour-submit;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: .25rem .5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  outline: none;

  &:hover {
    border: 1px solid #fff;
  }
}

.dashboard__secondary {
  display: flex;
  width: 100%;

  @media only screen and (min-width: 1280px) {
    width: 20%;
    display: block;
  }

  .dashboard-unit,
  .dashboard-unit:last-child {
    width: 33%;

    @media only screen and (min-width: 1280px) {
      width: auto;
      display: block;
    }
  }
}

.dashboard__data {
  font-size: 24px;
  margin: auto;
  padding: 1rem;

  @media only screen and (min-width: 1400px) {
    font-size: 30px;
  }

  h6 {
    margin: 0 0 .5rem ;
  }
}

.dashboard__playlists {
  display: flex;
  min-height: 350px;

  .dashboard__data {
    font-size: 24px;
  }
}

.dashboard__playlists-data {
  width: 20%;
  text-align: left;
}

.dashboard__playlists-form {
  padding: 1rem 1rem 0 1rem;
  text-align: left;
}

.dashboard__playlists-genres {
  width: 30%;

  h6 {
    margin: 1rem 0 0.5rem 0;
    font-size: 1rem;
  }
}

.dashboard__playlists-genres-pie-chart {
  min-height: 260px;
  position: relative;
}

.dashboard__playlists-genres-pie-chart-wrapper {
  margin-top: -30px;
}

.dashboard__playlists-breakdown {
  position: relative;
  width: 50%;

  h6 {
    margin: 1rem 0 0.5rem 0;
    font-size: 1rem;
  }
}

.dashboard__playlists-breakdown-wrapper {
  margin-left: -60px;
}

.dashboard__image {
  padding: 1rem;
  width: 100%;
}

.dashboard__no-data {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  height: calc(100vh - 225px);
  justify-content: center;

  a {
    color: #269CB7;
  }
}
