.container{
  position: relative;
  display: flex;
  &.container-height-full {
    height:100%;
  }
  &.container-tabview{
    margin-left:20px;
    margin-right:20px;
    height:100%;
  }
  &.container-table{
    margin:0 auto;
    background-color: $colour-background-mid;
  }
  &.column{
    flex-direction: column;
  }
  &.maxWidth{
    width:100%;
  }
  &.maxHeight{
    max-height: calc(100vh - 190px);
    overflow-y: auto;
  }
  .container-overlay{
    .container-table{
      margin-bottom:50px;
    }
  }
}
