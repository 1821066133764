#bubble-chart {
  height: 100%;
  overflow: visible;
  width: 100%;
}

.bubble-chart__bubble {
  cursor: pointer;
  fill: transparent;
  stroke: $colour-accent;
  stroke-width: 2;
}

.bubble-chart__bubble:hover {
  .bubble-chart__bubble-text {
    fill: #fff;
  }
}

.bubble-chart__bubble--selected {
  circle {
    fill: $colour-accent;
    transition: fill .5s;
  }

  .bubble-chart__bubble-text {
    fill: #fff;
  }
}

.bubble-chart__bubble-text {
  fill: $colour-accent;
  font-family: 'Walsheim', sans-serif;
  font-size: 16px;
  font-weight: normal;
  stroke-width: 0;
}
