.container-playlistDetails{
  height: 100vh;
  justify-content:space-between;
  overflow: hidden;
  .details-wrapper{
    overflow: scroll;
    width:33%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100% - 70px);
    padding-bottom: 70px;
    .playlist-name{
      padding:5px 0;
      font-size: 21px;
    }
    .details-top{
      display: flex;
      margin:12px 0;
      .image-wrapper{
        width:50%;
        padding:0 15px 0 0;
        img{
          width: 100%;
        }
      }
      .meta-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        div{
          font-size: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .tracks-wrapper{
    height: calc(100vh - 180px);
    width:66%;
    margin: 0 0 0 33px;
    overflow: scroll;
    .tracklist {
      list-style-type: none;
      margin:0;
      overflow: auto;
      padding: 0 21px 0 0;
    }

    .tracklist-item {
      display: flex;
      position: relative;
      border-bottom: 1px solid $colour-background-light;
      padding: .5rem;

      &:last-child {
        border: none;
      }
      .icon-play{
        margin-right: 1rem;
        padding-top: 2px;
      }
      .tracklist-track {
        flex: 1;
        .tracklist-track-name {
          color: $colour-text;
          font-size: .9rem;
        }

        .tracklist-artist {
          color: $colour-text-mid;
          font-size: .75rem;
        }
      }
      .tracklist-meta{
        display: flex;
        flex-direction: row;
        width:81px;
        justify-content:space-between;
        .tracklist-duration {
          font-size: 12px;
        }
        .energy{
          font-size: 12px;
        }
        .tracklist-new{
          font-size: 12px;
        }
        .tracklist-add-to-playlist{
          .icon {
            cursor: pointer;
          }
          .tooltip {
            font-size: 14px;
          }
        }
      }
      &--playing {
        background: $colour-text-link;
        .icon, .energy{
          color:#ffffff;
        }
        .tracklist-track{
          .tracklist-artist{
            color:#ffffff;
          }
        }
      }
    }
  }
}
