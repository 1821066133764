.table-row-loading{
  &:hover{
    td{
      background-color: $colour-background-mid !important;
    }
  }
  td{
    height: 75vh;
    border: none;
  }
}
