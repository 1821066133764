.container-player{
  position: absolute !important;
  bottom:0;
  left:0;
  height:70px;
  width:100%;
  background: linear-gradient(45deg,$colour-background-alt,$colour-background-light);
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 3000;
  .container-controls{
    align-items: center;
    .icon-play{
      width:60px;
      height:60px;
      margin:0 10px;
    }
    .icon-rewind, .icon-fastforward{
      width:30px;
      height:30px;
    }
  }
  .icon-list, .icon-add{
    width:30px;
    height:30px;
    margin:0 5px;
  }
  .container-waveform{
    min-width:70%;
    margin:0 20px;
    display:flex;
    align-items: center;
  }

  .player__queue-overlay-btn {
    cursor: pointer;
    position: relative;

    .badge {
      background-color: $colour-text-link;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      position: absolute;
      top: -4px;
      right: -8px;
      width: 20px;
    }
  }
}
