.confirm-wrapper {
  background: rgba(0 ,0 ,0 , .75);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;

  .confirm {
    background: $colour-background;
    padding: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;

    p {
      margin: 0 0 2rem 0;
    }
  }

  .confirm__controls {
    text-align: right;

    button {
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      padding: .25rem .5rem;
      margin-left: 1rem;
      min-width: 100px;
      width: auto;

      &:hover {
        border: 1px solid #fff;
      }
    }

    .confirm__confirm-btn {
      background: $colour-submit;
    }

    .confirm__cancel-btn {
      background: $colour-error ;
    }
  }
}
