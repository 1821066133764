.energy{
  display: flex;
  justify-content: center;
  align-items: center;
  &.energy-1, &.energy-2{
    color:#4b60b3;
    .icon{
      color:#4b60b3;
    }
  }
  &.energy-3, &.energy-4{
    color:#4ba0b3;
    .icon{
      color:#4ba0b3;
    }
  }
  &.energy-5, &.energy-6{
    color:#f0b35b;
    .icon{
      color:#f0b35b;
    }
  }
  &.energy-7, &.energy-8{
    color:#d97934;
    .icon{
      color:#d97934;
    }
  }
  &.energy-9, &.energy-10{
    color:#c5502b;
    .icon{
      color:#c5502b;
    }
  }
  .energy-number{
    width:14px;
    text-align: end;
  }
}
