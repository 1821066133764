.library-advanced-search-wrapper{
  width: calc(100% - 40px);
  margin:0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height:100%;
  .library-advanced-search-left{
    width:21%;
    height:100%;
    overflow: scroll;
    padding:0 9px 70px 9px;
    background-color: $colour-background-mid;
    h1{
      margin:12px 0;
      font-size: 23px;
    }
    .dropdown-selector{
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin:5px;
      .icon-wrapper{
        margin-left:15px;
        .icon{
          width:18px;
          height:18px;
        }
      }
    }
    .panel{ 
      display: flex;
      flex-direction: column;
      max-width:100%;
      height:100%;
      align-content: flex-start;
      display: none;
      &.active{
        display: initial;
      }
    }
    .genres{
      .genre{
        width:100%;
        border-radius: 3px;
        border:1px solid $colour-background-light;
        padding:3px 0;
        margin:3px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        &:hover{
          border:1px solid $colour-text-link;
          background-color: $colour-background-light;
        }
      }
    }
  }
  .library-advanced-search-right{
    width:89%;
    padding:0 9px;
    .search-box-wrapper{
      .search-input{
        width:100%;
        outline: 0;
        border:0;
        padding:17px;
        width:100%;
        height:25px;
        color: $colour-text;
        background-color: $colour-background-light;
        &::placeholder{
          color: $colour-text;
        }
      }
    }
  }
}