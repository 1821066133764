.table-actions{
  background-color: $colour-background-mid;
  border-top-right-radius:5px;
  border-top-left-radius:5px;
  border-bottom:1px solid $colour-background-light;
  padding: 10px;
  display:flex;
  min-height: 55px;
  .table-actions-left{
    width:70%;
    display:flex;
    align-items: center;
  }
  .table-actions-right{
    width:30%;
    display:flex;
    align-items: center;
    justify-content: flex-end;
    .icon{
      width:25px;
      height:25px;
      margin:0 5px;
    }
  }
}
