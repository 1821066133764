.button{
  background-color: transparent;
  padding:10px 20px;
  margin:5px 0;
  border:1px solid $colour-background-light;
  outline:none;
  border-radius:4px;
  &.button-block{
    display: block;
    width:100%;
  }
  &--loading{
    position: relative;

    .loader-wrapper {
      display: flex;
      align-items: center;
      height: 28px;
      position: static;
      margin: 0 auto;
      width: 28px;
    }
  }
  &:hover{
    border:1px solid $colour-text-link;
    background-color: $colour-background-light;
  }
  &:disabled{
    //color: $colour-text-disabled;
    border:1px solid $colour-text-link;
    background-color: $colour-background-light;
  }
  &:disabled:hover {
    cursor: not-allowed;
    border:1px solid $colour-text-link;
    background-color: $colour-background-light;
  }
  &.button-main{
    background-color: $colour-text-link;
    &:hover{
      border:1px solid $colour-text-link;
      background-color: $colour-background-light;
    }
  }
  &.button-bottom{
    position: absolute;
    bottom:3px;
  }
  &--warning {
    background-color: $colour-accent-alt;
    color: $colour-background;

    svg {
      stroke: $colour-background;
    }

    &:hover {
      color: #fff;

      svg {
        stroke: #fff;
      }
    }
  }
}
