.container-nav{
  min-height:60px;
  width:100%;
  display: flex;
  .container-nav-left{
    display: flex;
    width:70%;
    svg{
      max-height: 30px;
      max-width: 30px;
      min-width: 30px;
      margin:20px 20px 0 27px;
    }
    a{
      padding:24px 20px 20px 20px;
      margin:0 2px;
      text-decoration: none;
      color:$colour-text-weak;
      font-size: 14px;
      &:hover{
        box-shadow: inset 0px -2px 0px 0px $colour-text-weak;
        color: $colour-accent-alt;
      }
      &.is-active{
        box-shadow: inset 0px -2px 0px 0px $colour-accent;
        color: $colour-text;
      }
    }
  }
  .container-nav-right{
    width:30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right:20px;
    .icon-wrapper {
      height:30px;
      width:30px;
      margin:15px 20px 0;

      .icon {
        cursor: pointer;
        height: 100%;
        width: 100%
      }

      .tooltip-left {
        margin-right: 6px;
      }
    }
  }
}

.nav-chat {
  iframe {
    right: 200px !important;
    top: 0 !important;
  }
}
