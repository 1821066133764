.tooltip{
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  background-color: $colour-accent;
  padding:3px 7px 3px 5px;
  border-radius: 2px;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  color: $colour-text;
  font-weight: bold;
  pointer-events: none;
  &.tooltip-top{
    top:-10px;
  }
  &.tooltip-left{
    top:0;
    right: 100%;
    &:after{
      content:'';
      position:absolute;
      width: 16px;
      height: 16px;
      right: -10px;
      top:50%;
      transform:translate(-50%,-50%) rotate(-45deg);
      background-color:$colour-accent;
      z-index: -1;
    }
  }
  &.tooltip-right{
    top:0;
    left: 100%;
    &:after{
      content:'';
      position:absolute;
      width: 16px;
      height: 16px;
      left: 5px;
      top:50%;
      transform:translate(-50%,-50%) rotate(-45deg);
      background-color:$colour-accent;
      z-index: -1;
    }
  }
}
