.container-playlists {
  .playlists-dashboard__header {
    border-bottom:1px solid $colour-background-light;
    font-size: 13px;
    letter-spacing: 4px;
    margin: 5px 20px;
    padding:10px;
    text-align: center;
    text-transform: uppercase;
  }
}
