.container-onboarding {
  overflow: auto;
  padding: 2rem;
}

.onboarding {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}

.onboarding__logo {
  width: 32px;
  margin: 0 auto 2rem auto;

  svg {
    height: 100%;
    width: 100%;
  }
}

.onboarding__create-venue {
  max-width: 800px;
  margin: 0 auto;
}

.onboarding__header {
  text-align: center;
  margin: 2rem 0;
  min-height: 42px;
}

.onboarding__selection-header {
  text-align: center;
  margin: 1rem 0 0 0;
}

.onboarding__intro {
  font-size: 21px;
  margin: 3rem 0 2rem 0;
  text-align: center;
}

.onboarding__features {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto 1rem auto;
  padding: 1rem;
}

.onboarding__feature-item {
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: left;
  margin-bottom: 2rem;
  width: 50%;

  .icon-wrapper {
    display: inline-block;
    height: 40px;
    margin-right: 1rem;
    width: 40px;

    .icon {
      color: white;
      height: 100%;
      width: 100%;
    }
  }
  p {
    margin: 0;
  }
}

.onboarding__button {
  text-align: center;
}

.onboarding__success {
  align-items: center;
  display: flex;
  margin: 12rem auto 0 auto;
  max-width: 730px;

  h1 a {
    color: $colour-accent;
  }
}

$success-icon-size: 140px;

.onboarding__success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $success-icon-size;
  min-width: $success-icon-size;

  .icon-wrapper {
    animation-name: success-animation;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    height: $success-icon-size;
    width: $success-icon-size;
    transform: rotate(45deg);

    .icon {
      color: white;
      height: 100%;
      width: 100%;
    }
  }
}

@keyframes success-animation {
  0% {
    height: 0;
    width: 0;
    transform: rotate(90deg) translate(-99px, 99px);
  }

  100% {
    height: $success-icon-size;
    width: $success-icon-size;
    transform: rotate(45deg) translate(0, 0);
  }
}
